import { Button, Form, Input, Space } from 'antd';
import { useCallback, useId } from 'react';

import { useGuestLoginMutation } from '../../api/view-api';
import { TPatchFioPayload } from '../../api/view-api/types';
import { DarkTheme } from '../../styles';
import NotificationConfigProvider from './notification-config-provider';
import { TBaseNotificationProps } from './types';

type TProps = TBaseNotificationProps;

export const openGuestNotification = ({
  notificationApi,
  t,
  parentNoticeKey,
}: TProps) => {
  const [form] = Form.useForm<TPatchFioPayload>();
  const notificationKey = useId();

  const [guestLogin, { isLoading }] = useGuestLoginMutation();

  const handleBack = useCallback(() => {
    notificationApi.destroy(notificationKey);
  }, []);

  const handleFormFinish = useCallback(async (values: TPatchFioPayload) => {
    try {
      await guestLogin(
        `${values.firstName} ${values.middleName ? values.middleName + ' ' : ''}${values.lastName}`
      ).unwrap();
      // notificationApi.destroy(notificationKey);
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  }, []);

  return useCallback((parentOpen: () => void) => {
    notificationApi.destroy(parentNoticeKey);
    setTimeout(() => {
      notificationApi.open({
        message: t('auth.guestTitle'),
        key: notificationKey,
        description: (
          <NotificationConfigProvider>
            <Form
              form={form}
              layout="vertical"
              requiredMark={false}
              style={{
                marginTop: 20,
                width: '100%',
              }}
              onFinish={handleFormFinish}
            >
              <Form.Item
                label={t('form.firstName')}
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t('rules.required'),
                  },
                ]}
              >
                <Input size="large" type="firstName" />
              </Form.Item>
              <Form.Item
                label={t('form.lastName')}
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: t('rules.required'),
                  },
                ]}
              >
                <Input size="large" type="lastName" />
              </Form.Item>
              <Form.Item label={t('form.middleName')} name="middleName">
                <Input size="large" type="middleName" />
              </Form.Item>
            </Form>
            <Space>
              <Button
                type="primary"
                onClick={form.submit}
                size="large"
                loading={isLoading}
              >
                {t('button.continue')}
              </Button>
              <Button
                type="primary"
                ghost
                onClick={() => {
                  handleBack();
                  setTimeout(() => {
                    parentOpen();
                  }, 100);
                }}
                size="large"
                style={{
                  color: DarkTheme.COLORS.WHITE._100,
                }}
              >
                {t('button.back')}
              </Button>
            </Space>
          </NotificationConfigProvider>
        ),
        placement: 'topRight',
        duration: 0,
        closeIcon: false,
      });
    }, 100);
  }, []);
};
