import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { authSelector, logoutAction } from '../state/features/auth/auth.slice';

const BASE_API_PATH =
  process.env.API_HOST ?? 'https://playai-backend.aoneiro.com/';

const baseQueryWithAuth = fetchBaseQuery({
  baseUrl: BASE_API_PATH,
  prepareHeaders: (headers, { getState }) => {
    const { authorizationToken } = authSelector(getState() as never);
    if (authorizationToken) {
      headers.set('Authorization', `Bearer ${authorizationToken}`);
    }
    return headers;
  },
});

export const baseEmptyApi = createApi({
  reducerPath: 'api',
  baseQuery: async (args, api, extraOptions) => {
    const result = await baseQueryWithAuth(args, api, extraOptions);

    if (result.error?.status === 401) {
      await api.dispatch(logoutAction());
    }

    return result;
  },
  endpoints: () => ({}),
});
